// External Code Only!
window.jQuery = require('jquery');

// eslint-disable-next-line no-multi-assign
window.jQuery = window.$ = $;

window.Popper = require('popper.js');

// Load libraries!
require('breakpoints');
